import type { TextColor } from '@base/components/ui/text'
import { HODL } from '@base/types/hodl'
import type {
  UserSniperPool,
  UserPool,
  ActivePool,
} from '@base/types/sniperPools'
import { Sniper } from '@base/types/tokens'
import type { AllTokenType, OriginalAllTokenType } from '@deorderbook/shared'
import Big from 'big.js'
import dayjs from 'dayjs'
import type { DeOrderType } from '~/components/business/deorder/types'
import type { StakedInfoProgressData } from '~/components/business/staked-info-progress'

export interface UsePoolPageInfoReturnType {
  deorderType: ComputedRef<DeOrderType>
  buyOrSell: ComputedRef<TextColor>
  isHODLPool: ComputedRef<boolean>
  token: ComputedRef<{ type: AllTokenType; symbol: OriginalAllTokenType }>
  strikePrice: ComputedRef<{
    raw: string
    formatted: string
  }>
  exerciseTimestamp: ComputedRef<{
    timestamp: string | undefined
    formatted: string | undefined
  }>
  vapr: ComputedRef<{
    raw: string
    formatted: string
  }>
  optionAddress: ComputedRef<string>
  myStaked: ComputedRef<{
    amount: string
    usd: string
  }>
  tvl: ComputedRef<{
    amount: string
    usd: string
  }>
  percentage: ComputedRef<string>
  dobReward: ComputedRef<{ amount: string; formatted: string; usd: string }>
  stakedInfoProgressData: ComputedRef<StakedInfoProgressData>
}

export function usePoolPageInfo(
  pool: Ref<ActivePool>,
  matchingUserPool: Ref<UserPool | undefined>,
): UsePoolPageInfoReturnType
export function usePoolPageInfo(pool: Ref<UserPool>): UsePoolPageInfoReturnType
export function usePoolPageInfo(
  pool: Ref<ActivePool | UserPool>,
  matchingUserPool?: Ref<UserPool | undefined>,
): UsePoolPageInfoReturnType {
  const { getOriginalSymbol, getTokenPrice } = useTokens()
  const deorderType = computed(
    () =>
      (pool.value.token === Sniper.uSNIPER || pool.value.token === HODL.uHODL
        ? 'BUY'
        : 'SELL') as DeOrderType,
  )
  const buyOrSell = computed(() => {
    const { isScrollNetwork } = useNetwork()
    if (deorderType.value === 'BUY') return 'buy'
    if (isScrollNetwork.value) return 'e-hodl'
    return 'sell'
  })
  const isHODLPool = computed(() => pool.value.token.endsWith('HODL'))
  const token = computed(() => {
    return {
      type: pool.value.token,
      symbol: getOriginalSymbol(pool.value.token),
    }
  })
  const strikePrice = computed(() => {
    const raw = (pool.value as UserSniperPool)?.strikePrice ?? '0'
    return { raw, formatted: formatStrikePrice(raw) }
  })

  const exerciseTimestamp = computed(() => {
    return {
      timestamp: (pool.value as UserSniperPool).exerciseTimestamp ?? undefined,
      formatted: (pool.value as UserSniperPool).exerciseTimestamp
        ? dayjs(
            +((pool.value as UserSniperPool).exerciseTimestamp ?? 0) * 1000,
          ).format('DD MMM')
        : undefined,
    }
  })
  const vapr = computed(() => {
    return {
      raw: pool.value.apr,
      formatted: formatPercentage(pool.value.apr),
    }
  })
  const optionAddress = computed(() => pool.value.address)

  const myStaked = computed(() => {
    let amount = '0'
    let usd = '0'
    if (matchingUserPool) {
      amount = matchingUserPool.value?.stakedAmount?.value?.toString() ?? '0'
      usd = matchingUserPool.value?.stakedAmount?.usd?.toString() ?? '0'
    } else {
      amount = (pool.value as UserPool).stakedAmount.value.toString() ?? '0'
      usd = (pool.value as UserPool).stakedAmount.usd.toString() ?? '0'
    }

    return {
      amount,
      usd,
    }
  })

  const tvl = computed(() => {
    const amount = pool.value.tvl.amount.toString()
    const usd = pool.value.tvl.usd.toString()
    return {
      amount,
      usd,
    }
  })
  const percentage = computed(() => {
    if ((pool.value as UserPool).stakedAmount) {
      return Big((pool.value as UserPool).stakedAmount.value)
        .div(pool.value.underlying.value)
        .times(100)
        .toString()
    } else return '0'
  })
  const dobReward = computed(() => {
    const tokenPrice = getTokenPrice('DOB').value
    if ((pool.value as UserPool).dobAmount) {
      return {
        amount: (pool.value as UserPool).dobAmount,
        formatted: numberFormat((pool.value as UserPool).dobAmount),
        usd: Big((pool.value as UserPool).dobAmount ?? '0')
          .times(tokenPrice)
          .toString(),
      }
    } else return { amount: '0', formatted: '0', usd: '0' }
  })

  const stakedInfoProgressData = computed<StakedInfoProgressData>(() => {
    return {
      token: token.value.type,
      staked: {
        amount: myStaked.value.amount,
        amountUSD: myStaked.value.usd,
      },
      tvl: {
        amount: tvl.value.amount,
        amountUSD: tvl.value.usd,
      },
    }
  })

  return {
    deorderType,
    buyOrSell,
    isHODLPool,
    token,
    strikePrice,
    exerciseTimestamp,
    vapr,
    optionAddress,
    myStaked,
    tvl,
    percentage,
    dobReward,
    stakedInfoProgressData,
  }
}
